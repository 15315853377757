import * as React from "react";
import Heading from "@atoms/Heading";
import Button from "@atoms/Button";
import Image from "next/image";
import ChildAssessmentServices from "./ChildAssessmentServices";
import Link from "next/link";
import HeadingNew from "@atoms/HeadingNew";
const HelpYourChildSection = ({
    childAssessmentServicesData,
    handleBookAssessment,
}) => {
    return (
        <section className="relative z-50 bg-[center_top_-20%] md:bg-bottom bg-cover bg-[#CFE7D6] md:bg-helpYourchild bg-no-repeat  overflow-hidden">
            <div className="absolute -bottom-6 left-20 lg:block hidden  -rotate-12 max-w-[90px] md:max-w-max">
                <Image
                    src="/assets/images/sunshine/banner32.png"
                    width={170}
                    height={122}
                />
            </div>
            <div className="absolute z-50 -bottom-20 left-16 block md:hidden ">
                <Image
                    src="/assets/images/sunshine/helpChildCrown.svg"
                    width={400}
                    height={250}
                />
            </div>
            <div className="w-11/12 md:max-w-[1215px] mx-auto relative">
                <div className="mb-4 lg:mb-0">
                    <HeadingNew
                        type="h1"
                        fontSize="text-xl lg:text-[40px]"
                        fontWeight="font-semibold "
                        className=" leading-normal py-8 lg:pt-[86px] lg:pb-[68px]  text-center  "
                    >
                        How can we help your child
                    </HeadingNew>
                </div>
                <div className="flex justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8">
                        {childAssessmentServicesData.map((obj) => {
                            return (
                                <ChildAssessmentServices
                                    key={obj.heading}
                                    imageSrcSet={obj.img}
                                    heading={obj.heading}
                                    description={obj.description}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="py-6 md:py-20">
                    <Link href="https://www.lissun.app/connect-with-the-best-mental-health-counsellor-&-therapist-in-india?from=sunshine">
                        <Button
                            className="w-full mx-auto  md:w-[164px] lg:w-[300px] md:h-11 lg:h-[60px] flex justify-center items-center"
                            variant="sunshineSecondary"
                            onClick={handleBookAssessment}
                        >
                            Book Assessment
                        </Button>
                    </Link>
                </div>
               

                <div className="static md:absolute -bottom-6 -right-28">
                    <div className="hidden md:block">
                        <Image
                            src="/assets/images/sunshine/banner33.svg"
                            width={460}
                            height={474}
                        />
                    </div>
                    
                </div>
            </div>
        </section>
    );
};

export default HelpYourChildSection;
