import Text from "@atoms/Text";
import Image from "next/image";
import React from "react";

const LocationCard = ({ textColor, location, address, phone, email, viewMorelink }) => {
    return (
        <>
            <div
                className={`p-4 md:p-5 rounded-2xl w-11/12 md:w-[270px] h-[240px]md:h-[212px]  bg-secondary-930  shadow-locationCard     `}
            >
                <div className="flex flex-row items-end md:items-start justify-start gap-6 md:gap-1 md:flex-col overflow-scroll md:overflow-hidden">
                    <div>
                        <header className="header w-16 ">
                            <h1 className={`${textColor} text-base md:text-2xl font-semibold md:font-bold leading-6 md:leading-8 text-left whitespace-nowrap `}>
                                {location}
                            </h1>
                            <Text className="text-base leading-6 text-left whitespace-nowrap mt-1">
                                {address}
                            </Text>
                        </header>
                    </div>
                    <div className="contact-info">
                        <Text className="text-base text-left flex items-center gap-2 whitespace-nowrap mt-0 md:mt-3">
                            <div className="mt-1.5">
                                <Image
                                    src="/assets/images/sunshine/Call.jpg"
                                    width={16}
                                    height={16}
                                />
                            </div>
                            {phone}
                        </Text>
                        <Text className=" text-base text-left flex items-center gap-2 whitespace-nowrap">
                            <div className="flex justify-center">
                                <Image
                                    src="/assets/images/sunshine/mail121.svg"
                                    width={16}
                                    height={16}
                                />
                            </div>
                            {email}
                        </Text>


                    </div>
                </div>
                <div className="text-center flex justify-center items-center w-full  mx-auto  mt-2 lg:mt-4">
                    <a href={viewMorelink} target="_blank" rel="noopener noreferrer" className="text-[#EF833A] underline mr-2 text-sm ">
                        View More
                    </a>
                    <Image
                        src="/assets/images/Vector.svg"
                        width={15}
                        height={15}
                        className="mt-1.5"
                    />
                </div>
            </div>
        </>
    );
};

export default LocationCard;

