import Container from "@atoms/Container";
import HeadingNew from "@atoms/HeadingNew";
import TextNew from "@atoms/TextNew";
import Image from "next/image";
import * as React from "react";
import BookingFormSunshine from "./BookingFormSunshine";
const HeroBanner = ({ consultationData, onClose }) => {
    return (
        <section className="relative bg-top md:bg-center bg-cover  bg-hero-banner-mob bg-no-repeat md:bg-hero-banner-sunshine z-20 md:pb-0 pb-[10px] overflow-hidden">
            <div className="absolute md:hidden block -bottom-[22px] z-10">
                <Image
                    src="/assets/images/sunshine/HeroBottom.svg"
                    width={400}
                    height={35}
                />
            </div>
            <Container type="type11">
                <div className="flex-none md:flex justify-between h-full pt-4 md:pt-11">
                    <div className="max-w-[600px] z-30 py-5 md:p-0 md:ml-0 lg:mt-10 md:text-left">

                        <HeadingNew
                            type="h1"
                            fontSize="text-xl lg:text-[40px]"
                            fontWeight="font-semibold "
                            className=" leading-normal text-left mb-4 text-white  "
                        >
                            Unlock your child’s potential and help them thrive!
                        </HeadingNew>

                        <TextNew
                            type="caption"
                            fontSize="text-xs lg:text-base"
                            className="text-left mb-2 font-normal leading-5 text-white">
                            We at Sunshine by LISSUN have started a chain of
                            clinics to provide world class child development
                            services. We specialise in neurodevelopmental and
                            behavioral disorders especially Autism Spectrum,
                            ADHD & Learning Disorders.
                        </TextNew>

                    </div>
                    <div className="relative pt-11 md:pt-11 text-center">
                        <div className="text-center absolute -mt-[45px] -left-[100px] w-[120%] md:w-[150%] hidden md:block">
                            <Image
                                src="/assets/images/sunshine/cloudHero.png"
                                width={630}
                                height={480}
                                className=""
                            />
                        </div>

                        <div className={"relative"}>
                            <BookingFormSunshine className="relative z-50" onClose={onClose} />
                            <div className="absolute md:hidden block -right-4 -bottom-8 z-10">
                                <Image
                                    src="/assets/images/sunshine/CloudFormRight.png"
                                    width={75}
                                    height={45}
                                />
                            </div>
                            <div className="absolute md:hidden block -left-4 -bottom-8 z-10">
                                <Image
                                    src="/assets/images/sunshine/CloudFormLeft.png"
                                    width={75}
                                    height={45}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
            <div className="centers mt-10 lg:mt-16 mb-5 relative">
                <div>

                    <div className="absolute hidden md:block -top-12 lg:-top-16 xl:-top-20 lg:left-10 ">
                        <Image
                            src="/assets/images/sunshine/Grouphero.svg"
                            width={1400}
                            height={118}
                        />
                    </div>
                    <div className="absolute md:hidden block top-[9px]">
                        <Image
                            src="/assets/images/sunshine/HeroAnimalillustration.png"
                            width={400}
                            height={45}
                        />
                    </div>

                </div>

                <HeadingNew
                    type="h1"
                    fontSize="text-base lg:text-2xl"
                    fontWeight="font-medium"
                    className=" leading-normal text-center md:hidden block mb-6  "
                >
                    Contact our Centers:
                </HeadingNew>

                <div className="w-full relative z-10 grid grid-cols-2 md:grid-cols-5 gap-0 justify-center content-center px-4 font-medium bg-[#FEFBEF] shadow-lg ">

                    <HeadingNew
                        type="h1"
                        fontSize="text-base lg:text-2xl"
                        fontWeight="font-medium"
                        className=" leading-normal text-center hidden md:block  max-w-[250px] m-auto"
                    >
                        Contact our Centers:
                    </HeadingNew>

                    <div className="flex flex-col flex-1  justify-center  items-center px-2 lg:px-4 py-6 border-b lg:border-l border-gray-200 border-solid  ">
                        <a href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-delhi" target="_blank" rel="noopener noreferrer">
                            <HeadingNew type="h4" fontWeight="font-semibold" fontColor="text-[#EF833A]" className="text-2xl">Delhi</HeadingNew>
                        </a>
                        <TextNew variant="body" className="mt-4 text-neutral-800">+91 9821688144</TextNew>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-center px-2 lg:px-4 py-6 border-l border-b border-gray-200 border-solid  ">
                        <a href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-gurgaon" target="_blank" rel="noopener noreferrer">
                            <HeadingNew type="h4" fontWeight="font-semibold" fontColor="text-[#F47A82]" className="text-2xl">Gurgaon</HeadingNew>
                        </a>
                        <TextNew variant="body" className="mt-4 text-neutral-800">+91 9717857568</TextNew>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-center px-2 lg:px-4 py-6 lg:border-l  border-gray-200 border-solid  ">
                        <a href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-lucknow" target="_blank" rel="noopener noreferrer">
                            <HeadingNew type="h4" fontWeight="font-semibold" fontColor="text-[#5C7AAF]" className="text-2xl">Lucknow</HeadingNew>
                        </a>
                        <TextNew variant="body" className="mt-4 text-neutral-800">+91 9717857643</TextNew>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-center px-2 lg:px-4 py-6 border-l border-gray-200 border-solid  ">
                        <a href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-pune" target="_blank" rel="noopener noreferrer">
                            <HeadingNew type="h4" fontWeight="font-semibold" fontColor="text-[#88C399]" className="text-2xl">Pune</HeadingNew>
                        </a>
                        <TextNew variant="body" className="mt-4 text-neutral-800">+91 9717858269</TextNew>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default HeroBanner;