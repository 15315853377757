import * as React from "react";
import Heading from "@atoms/Heading";
import Text from "@atoms/Text";
import Image from "next/image";
import Container from "@atoms/Container";
import SunshineStorySlider from "./SunshineStorySlider";
import HeadingNew from "@atoms/HeadingNew";
import TextNew from "@atoms/TextNew";
const SunshineStorySection = () => {
    return (
        <section className="relative bg-secondary-800  md:pt-20 pt-[100px] lg:pt-[15px] pb-[120px] md:pb-[108px]">
            <div className="absolute top-0 left-0 max-w-[120px] md:max-w-max">
                <div className="block md:hidden">
                    <Image
                        src="/assets/images/sunshine/story-icon-mob-1.png"
                        width={120}
                        height={130}
                    />
                </div>
                <div className="hidden md:block">
                    <Image
                        src="/assets/images/sunshine/story-icon-1.png"
                        width={156}
                        height={221}
                    />
                </div>
            </div>
            <div className="absolute top-10 right-0 max-w-[170px] md:max-w-max">
                <div className="block md:hidden">
                    <Image
                        src="/assets/images/sunshine/story-icon-mob-2.png"
                        width={120}
                        height={80}
                    />
                </div>
                <div className="hidden md:block max-w-[173px] md:max-w-[148px] lg:max-w-[286px]">
                    <Image
                        src="/assets/images/sunshine/story-icon-2.png"
                        width={156}
                        height={221}
                    />
                </div>
            </div>
            <Container type="type12">
                <div className="flex flex-col justify-center  items-center">
                    <div className="flex items-center gap-4">

                        <HeadingNew
                            type="h1"
                            fontSize="text-xl lg:text-[40px]"
                            fontWeight="font-semibold "
                            className=" leading-normal py-6 lg:pt-16  text-center text-secondary-700 md:-ml-14 "
                        >
                            The Sunshine Story
                        </HeadingNew>
                        <div className="hidden md:block">
                            <Image
                                src="/assets/images/sunshine/vector-bird.png"
                                width={51}
                                height={45}
                            />
                        </div>
                    </div>

                    <TextNew
                        variant="caption"
                        className="max-w-130 lg:max-w-[1035px] font-normal mb-5 md:mb-20 text-center"
                    >
                        While our co-founders Krishna Veer Singh and Tarun Gupta
                        were building LISSUN for solving mental health in India
                        at scale, they recognised a need to build something
                        specifically for children, and soon after, Sunshine came
                        into being. We have transformed from just an idea to
                        several locations across India and have big plans for
                        the future!
                    </TextNew>


                    <SunshineStorySlider />
                    <div className="block md:hidden bg-[#FCF6DE]">
                        <Image
                            src={"/assets/images/sunshine/SunshineStoryMobile12.png"}
                            width={360}
                            height={484}
                        />
                    </div>
                </div>
            </Container>
            <div className="absolute -bottom-1.5 left-0 max-w-[160px] md:max-w-max">
                <Image
                    src="/assets/images/sunshine/story-icon-3.png"
                    width={276}
                    height={89}
                />
            </div>
            <div className="absolute -bottom-1.5 right-0 max-w-[160px] md:max-w-max">
                <Image
                    src="/assets/images/sunshine/story-icon-4.png"
                    width={276}
                    height={89}
                />
            </div>
        </section>
    );
};

export default SunshineStorySection;
